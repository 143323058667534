<template>
<v-app>
    <div class="mainCommunityGuideLine">


<div>
<v-row no-gutters>
    <v-col md="4" cols="12">
            <div id="lottie" ref="lottie"></div>
    </v-col>
    <v-col md="8" cols="12" class="colsium">
        <h1>コミュニティガイドライン</h1><br>
        <div class="explainCommunityGuideLine">
            <p>
                Audreyは世界中の人が愛せるプラットフォームを目指します。そして今はインターネットを通したコミュニケーションは当たり前になっています。アプリの空間で多くの人たちが、交流する場所、人のことをコミュニティといいます。楽しい空間、感動できる空間、人生を豊かにできる空間、自分を愛せる空間を皆さまと共に目指しましょう。
            </p>
        </div>
    </v-col>
    
    <v-col md="8" cols="12" class="colsium">
             <h1>コミュニティについて</h1><br>
        <div class="explainCommunityGuideLine">
            <p>
                最近はコミュニティという言葉をよく耳にしますが、コミュニティという言葉の意味は環境やアプリケーション、様々な環境でコミュニティの意味が変化します。
                Audreyでは、人が交流する場、人が集まる場所。町だと町内会。学校の教室や部室。仕事終わりの喫煙所。様々な場所でコミュニティがあると思います。AudreyはSNSです。
                SNSもコミュニティの一つです。誰が何を言っているのか、どんな反応をするのか、私たちは見ることが出来ます。そして感じ取ることも出来ます。
                推測することも、疑うことも。否定することも、感謝することも、歓喜することも。賞賛することもできます。インターネットを経由していることで、世界中の人とコミュニティに所属することが可能です。しかしコミュニティはオンラインであろうとオフラインであろうと人たちが集まる場所であることに
                変わりはありません。そしてコミュニティは自分の存在する場所でもあります。あなたはあなた自身が宙に浮いた神のような存在に感じることもあるかもしれません。頭の切れるプロデューサー、最大権限を持った大統領のように感じるかもしれませんが、Audreyにいる人たちは全員同じ機能を使うことができる、平等な人たちの集まりです。
                今一度、コミュニティの在り方、デジタルソーシャルの在り方を考えてみてください。
            </p>
        </div>
    </v-col>
        <v-col md="4" cols="12">
            
    </v-col>
            <v-col md="4" cols="12">
            
    </v-col>
        
    <v-col md="8" cols="12" class="colsium">
             <h1>Kaoについて</h1><br>
        <div class="explainCommunityGuideLine">
            <p>
               AudreyではユーザーのことをKaoと総称します。どうしてユーザーと言わないのかというと、一つのGoogleアカウントがあれば、Audreyの中で複数のアカウントを持つことができるからです。すなわちユーザーのKaoだからです。
               <br><br><b>どうして複数のKaoが作れるの？</b><br>
               私たち人は一人の人間として日々生活していますが、興味関心は人生のスケールよりも幅広く捉えており、様々な一面を持つことになると思います。人には言いづらい趣味や将来のビジョンを多くの人が抱えていると思います。声に出すことが難しくてもAudreyは安心してそのコミュニティーに自分の身を置くことが出来ます。
               コミュニティは様々ありますが、Audreyではコミュニティを作成することが出来ます。コミュニティのことをTableといいます。
                <br><br><b>Kaoを作成する場合の注意事項</b><br>
             Kaoは世間的に言われる裏アカになります。ですが裏アカが悪いものだとはAudreyは認識していません。しかしながら誹謗中傷や故人の尊厳を傷つける行為を目的としたKaoの作成は禁止されています。誹謗中傷に関するガイドラインは後に説明があります。
             <br>以下の目的を行うKaoの作成は禁止されています。<br>
             A.誹謗中傷を目的としたKaoの作成 B.Tableの内容と逸脱した広告やマーケティング、勧誘を目的としたKaoの作成 C.いじめを目的としたKaoの作成 D.露骨な性的コンテンツの投稿を目的としたKaoの作成 E.犯罪を目的としたKaoの作成。

            </p>
            
        </div>
        
    </v-col>
    
        <v-col md="8" cols="12" class="colsium">
             <h1>Tableについて</h1><br>
        <div class="explainCommunityGuideLine">
            <p>
               私たちは自由にTableを作成することが出来ます。Tableの作成を行うにはまずKaoが必要になります。Tableのテーマは自由ですが、すでに存在するTableを同じように作成するのは他のKaoが参加しにくいので注意が必要です。
               <br><br><b>宗教、政治に関するTable</b><br>
               Audreyは宗教や政治に参加することを否定しません。しかし昨今の選挙活動でSNSを持ち込んだ際に起こるトラブルを回避するためのものでもあります。政治への関心は非常に重要ですが、ユーザーが閲覧するTableを選ぶことは自由でないといけません。
               もちろん、政治に関するTableに参加することはAudreyとしても推奨しますが、Audreyとしては参加するTableは自由に選択できるということを尊重しました。宗教も同じです。信仰は自由であるべきだと思います。政治または宗教に関するTableの項目をチェックしてください。<br>
               <br><b>Tableの承認機能</b><br>
                Tableの参加はデフォルトでは承認制度はオフになっていますが、承認制度を設けることもできます。
                 <br><b>Table開設の注意事項</b><br>
                 以下の目的のためのTableの開設は禁止されています。
                A. 誹謗中傷を目的としたTableの開設。
                B. 個人を特定、個人情報を開示するためのTableの開設。
                C. いじめ、嫌がらせを目的としている。
                D. 犯罪を目的としている、著作権の侵害、違法アップロードを目的としている。
            </p>
        </div>
        
    </v-col>
          <v-col md="4" cols="12">
           
    </v-col>
              <v-col md="4" cols="12">

    </v-col>
              <v-col md="8" cols="12">
                   <h1>著名なKaoは公式のTableについて</h1><br>
                       <div class="explainCommunityGuideLine">
            <p>
              Kaoに著名性がある場合、Audreyでは公式マークを特定のKaoに付与することがあります。
               <br><b>公式マークを付与する条件</b><br>
               Tableの参加人数が1000人以上であり、コンテンツの投稿が継続的である必要があります。他にも様々な条件があります。
               
                 <br><b>Tableの譲渡</b><br>
                非公式ではないTableでも情報が正確で、コミュニティガイドラインを遵守しているTableであれば公式アカウントとして引き継ぎを行う場合があります。この引き継ぎに関しては自動で行われます。引き継ぎがあった場合は引き継ぐコンテンツの対象の団体、または著名な個人、Audreyからリワードを差し上げます。
            </p>
        </div>
          <h1>ConnectTableについて</h1><br>
                       <div class="explainCommunityGuideLine">
            <p>
             Tableに参加する際にKaoを作成する必要があります。ですが関連性のあるTableに関してはConnectTableというアクションを取ることが出来ます。例えば、AとBのTableに参加しようとすると、それぞれのTableに参加するために二つのKaoを作成する必要があります。ですがTableのオーナーが関連性のあるTableとConnectTableをすれば、新たにKaoを作成する必要はありません。
            同一のKaoで、ConnectTableとされたTableに参加することが可能です。
               <br><b>同一のKaoがTableを作成した場合</b><br>
               同一のKaoがTableを作成した場合、自動でConnectTableとして設定されます。もしConnectTableとして設定したくない場合は新たにKaoを作成してTableを開設してください。
                 <br><b>Tableの閉鎖、削除</b><br>
                KaoがTableを任意で削除することは出来ませんが、Tableオーナーの解除は可能です。その場合はオーナーが不在のTableとして表示されます。その場合は任意でオーナーとして参加することが可能です。Tableのオーナーが他のKaoをオーナーとして指名することもできます。
                ガイドライン違反したTableは凍結されることがあります。
            </p>
        </div>
    </v-col>
</v-row>
</div>
</div>
</v-app>
</template>
<style>
.colsium{
    margin-bottom: 35px;
}
.mainCommunityGuideLine{
    padding: 14px;
    background-image: linear-gradient(227deg, #091C3D 0%, #33135D 100%);
    height: 100%;
    border-radius: 21px;
    margin-top: 14px;
    box-shadow: 3px 14px 18px -1px rgba(0,0,0,0.50);
    min-width: 290px;    
}
.mainCommunityGuideLine h1{
   font-size: 21px;
}
#lottie{
    width: 100%;
    height: auto;
}
#lottie2{
    width: 100%;
    height: auto;
}
.explainCommunityGuideLine{
line-height: 200%;
}
.explainCommunityGuideLine h1{
margin-bottom: 21px;
}
.explainCommunityGuideLine p{
    color: white;
}
@media screen and (max-width:700px) { 
    .explainCommunityGuideLine p{
    color: white;
    font-size: 14px;
}
}
</style>
<script>
import lottie from 'lottie-web';
import animationData from '../assets/38322-thumb-run.json';
export default {
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.lottie, // document.getElementbyId('lottie') などでも OK
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
     
  },
};

</script>