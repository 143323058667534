<template>
  <v-app id="inspire">


    <v-main class="mains2">
      <div class="vertical-snap">
     <div class="slideWallax">
<div class =wordMargin>
<svg width="100%" height="100%" viewBox="0 0 1408 1007" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.898793467" font-family="HiraginoSans-W5, Hiragino Sans" font-size="121" font-weight="bold">
        <g id="Artboard" transform="translate(-16.000000, -5.000000)" fill="#FFFFFF">
            <g id="Group" transform="translate(16.000000, 5.000000)">
                <text id="Audreyは-説明的では-ありません">
                    <tspan x="0" y="106">Audreyは</tspan>
                    <tspan x="0" y="227">説明的では</tspan>
                    <tspan x="0" y="348">ありません</tspan>
                </text>
                <text id="直感のまま-操作できるようにデザインされ">
                    <tspan x="324" y="621">直感のまま</tspan>
                    <tspan x="324" y="742">操作できるように</tspan>
                    <tspan x="324" y="863">デザインされました</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>
</div>
     </div>

          <div class="slideWallbx">
         <div class=wordMargin>
<svg width="100%" height="100%" viewBox="0 0 1440 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.898793467" font-family="HiraginoSans-W5, Hiragino Sans" font-size="121" font-weight="bold">
        <g id="Group" fill="#FFFFFF">
            <text id="私たちはオフラインにいちばん近い-SNS">
                <tspan x="305" y="106">私たちはオフライン</tspan>
                <tspan x="305" y="227">にいちばん近い</tspan>
                <tspan x="305" y="348">SNSを目指しました</tspan>
            </text>
            <text id="自分の未来を、環境をより良くするために">
                <tspan x="0" y="677">自分の未来を、環境を</tspan>
                <tspan x="0" y="798">より良くするために</tspan>
            </text>
        </g>
    </g>
</svg>
         </div>
     </div>
          <div class="slideWallcx">
         <div class=wordMargin>

<svg width="100%" height="100%" viewBox="0 0 1440 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.898793467" font-family="HiraginoSans-W5, Hiragino Sans" font-size="121" font-weight="bold">
        <g id="Group" fill="#FFFFFF">
            <text id="興味や関心は-あなたの人生を-豊かにする">
                <tspan x="0" y="106">興味や関心は</tspan>
                <tspan x="0" y="227">あなたの人生を</tspan>
                <tspan x="0" y="348">豊かにすることが</tspan>
                <tspan x="0" y="469">できます</tspan>
            </text>
            <text id="Kaoを通して様々な-興味関心を-広げて">
                <tspan x="230" y="677">Kaoを通して様々な</tspan>
                <tspan x="230" y="798">興味関心を</tspan>
                <tspan x="230" y="919">広げていきましょう</tspan>
            </text>
        </g>
    </g>
</svg>
         </div>
     </div>
          <div class="slideWalldx">
         <div class=wordMargin>

<svg width="100%" height="100%" viewBox="0 0 1440 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.898793467" font-family="HiraginoSans-W5, Hiragino Sans" font-size="121" font-weight="bold">
        <g id="Group" fill="#FFFFFF">
            <text id="色々言いましたが、あなたの人生はあなたが">
                <tspan x="305" y="106">色々言いましたが、</tspan>
                <tspan x="305" y="227">あなたの人生は</tspan>
                <tspan x="305" y="348">あなたが主人公です</tspan>
            </text>
            <text id="自分がどんな人間で-どんなことが出来て">
                <tspan x="0" y="611">自分がどんな人間で</tspan>
                <tspan x="0" y="732">どんなことが出来て</tspan>
                <tspan x="0" y="853">どんな風に</tspan>
                <tspan x="0" y="974">なりたいのか？</tspan>
            </text>
        </g>
    </g>
</svg>
         </div>
     </div>
          <div class="slideWallex">
             <div class=rightBar2>
<div class=deviceImageClass>
        <img src="https://res.cloudinary.com/audreyjapan/image/upload/v1665906385/deviceAndIcon_znsshh.png">
        </div>
        <h2>AudreyをiOSから楽しもう</h2>
<div class="textMargin">
        <b>iOS版Audreyは無料でダウンロードいただけます。AppStoreからAudreyをインストールしてAudreyの全ての機能をお楽しみください。</b><br>
        </div>
           <div class="btns">
            <v-btn
      depressed
      color="primary"
      class="customeButton"
    >
       インストール 
    </v-btn>
  </div>
  <div class=explains><a>
    Audreyでは差別や誹謗中傷は禁止されています。<br>多くの人が楽しく利用できる環境の構築を目指します。<br>
    現在AudreyはiOSのみ対応しております。<br>AppStoreからインストールすることが可能です。
    </a><br><br><br>
    <b>© 2021 Audrey inc.</b>
    </div>
  </div>
     </div>
     </div>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    //
  }
</script>
<style>
.mains2{
  width: 100vw;
}
.vertical-snap {
height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}

.vertical-snap .slideWallax{
  scroll-snap-align: center;
}
.wordMargin{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 90%;
    height: 70%;
}
.slideWallax{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(227deg, #266DE8 0%, #772ADB 100%);
    position: relative;
  scroll-snap-align: start;
/* Sketch doesnt export gradient borders at this point */
}
.slideWallbx{
  width: 100%;
    height: 100vh;
  scroll-snap-align: start;
        position: relative;
   background-image: linear-gradient(180deg, #FF4081 4%, #F50057 94%);
   
}
.slideWallcx{
 width: 100%;
    height: 100vh;
  scroll-snap-align: start;
      position: relative;
background-image: linear-gradient(221deg, #00E1FD 4%, #FC007A 98%);
}
.slideWalldx{
        width: 100%;
    height: 100vh;
  scroll-snap-align: start;
      position: relative;
 background-image: linear-gradient(221deg, #35D74D 4%, #00F5B0 98%);
}
.slideWallex{
        width: 100%;
    height: 100vh;
  scroll-snap-align: start;
      position: relative;
    background-image: linear-gradient(227deg, #266DE8 0%, #772ADB 100%);
}
.rightBar2{
   background-color: transparent;
  width: 60%;
  height: 80%;
  display: block!important;
    padding: 14px;
  border-radius: 14px;
  transition: 1s;
  margin: auto;
}
.rightBar2 h2{
  text-align: center;
  color: white;
}
.rightBar2 b{
  color: white;
}
.rightBar2 img{
  width: 53%;
  margin: auto;
  text-align: center;
  
}
@media screen and (max-width:1300px) { 
  .rightBar2{
   background-color: transparent;
  width: 100%;
  height: 100%;
  display: block!important;
    padding: 14px;
  border-radius: 14px;
  transition: 1s;
  margin: auto;
}
.rightBar2 img{
  width: 80%;
  margin: auto;
  text-align: center;
  
}
}
</style>