import Vue from 'vue'
import App from './App.vue'
import router from './router' //追加
import vuetify from '@/plugins/vuetify'
Vue.config.productionTip = false
Vue.use(vuetify);
new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app')
