import Vue from 'vue'
import Router from 'vue-router'
import home from './views/Home'
import info from './views/info.vue'
import about from './views/About.vue'
import terms from './views/kiyaku.vue'
import comunityGuideLine from './views/communityGuidline.vue'
import dataAndAudrey from './views/dataPolicy.vue'
import privacyAndPolicy from './views/privacyAndPolicy.vue'
import Audrey from './views/Audrey.vue'
//import AdMob from './views/app-ads.txt'
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: home
    },
    {
        path: '/info',
        name: 'info',
        component: info
      },
      {
        path: '/AudreyAbout',
        name: 'about',
        component: about
      },
      {
        path: '/serviceAndPolicy',
        name: 'terms',
        component: terms
      },
      {
        path: '/communityGuideLIne',
        name: 'comunityGuideLine',
        component: comunityGuideLine
      },
      {
        path: '/AudreyAndData',
        name: 'Data',
        component: dataAndAudrey
      },
      {
        path: '/privacyAndPolicy',
        name: 'privacyAdnPolicy',
        component: privacyAndPolicy
      },
      {
        path: '/Audrey',
        name: 'AudreyBeta',
        component: Audrey
      }   
  ]
})