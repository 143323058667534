<template>
  <div id="app">
<v-app>


<v-app-bar class="mainBackGround">
<v-row justify="center"><router-link to="/"> <v-img
  lazy-src="https://res.cloudinary.com/audreyjapan/image/upload/v1665906396/ALogo_3x_slplh7.png"
  max-height="49"
  max-width="49"
  src="https://res.cloudinary.com/audreyjapan/image/upload/v1665906396/ALogo_3x_slplh7.png"
></v-img></router-link>
       
    </v-row>
    
  </v-app-bar >

 <div class="text-center menuApp">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color= #2c2c2e
          dark
          v-bind="attrs"
          v-on="on"
        >
          ▽
        </v-btn>
      </template>
<v-list>
  <v-list-item>
    <router-link to="/"><b>Audreyを始める</b></router-link>
  </v-list-item>
  <v-list-item>
    <router-link to="/AudreyAbout">Audreyについて</router-link>
  </v-list-item>
  <v-list-item>
    <router-link to="/serviceAndPolicy">利用規約</router-link>
  </v-list-item>
  <v-list-item>
    <router-link to="/communityGuideLIne">コミュニティガイドライン</router-link>
  </v-list-item>
    <v-list-item>
    <router-link to="/privacyAndPolicy">プライバシーポリシー</router-link>
  </v-list-item>
    <v-list-item>
    <router-link to="/AudreyAndData">Audreyのデータの取り扱い</router-link>
  </v-list-item>
      <!-- <v-list-item>
    <router-link to="/Audrey">Audreyブラウザバージョン Beta</router-link>
  </v-list-item> -->
  <v-list-item>
    <v-container>
      <v-row no-gutters>
        <v-col cols=12>
          <v-col cols=4 sm="4"> </v-col>
          <v-col cols=12>
            <v-container>
              <v-row no-gutters>
                <v-col cols="2">
                  <a href="https://www.instagram.com/audreyjapan/">
                    <v-img src="https://res.cloudinary.com/audreyjapan/image/upload/v1646206625/instagram_okospb.png" width="35px"></v-img>
                  </a>
                </v-col>
                <v-col cols="2">
                  <a href="https://twitter.com/Audrey_JPN">
                    <v-img src="https://res.cloudinary.com/audreyjapan/image/upload/v1646212221/twitter_xxqtd9.png" width="35px"></v-img>
                  </a>
                </v-col>
                <v-col cols="2">
                  <a href="www.pinterest.com/AudreyCommunityManager">
                    <v-img src="https://res.cloudinary.com/audreyjapan/image/upload/v1646211703/pinterest-round-logo_zimmxw.png" width="35px"></v-img>
                  </a>
                </v-col>
                <v-col cols="2">
                  <a href="kloss.page">
                    <v-img src="https://res.cloudinary.com/audreyjapan/image/upload/v1665906396/ALogo_3x_slplh7.png" width="35px"></v-img>
                  </a>
                </v-col>
                <v-col cols="2">
                  <a href="https://apps.apple.com/jp/app/discord-%E8%A9%B1%E3%81%9D%E3%81%86-%E3%83%81%E3%83%A3%E3%83%83%E3%83%88%E3%81%97%E3%82%88%E3%81%86-%E9%9B%86%E3%81%BE%E3%82%8D%E3%81%86/id985746746">
                    <v-img src="https://res.cloudinary.com/audreyjapan/image/upload/v1646210428/app_store_sbk0sw.png" width="35px"></v-img>
                  </a>
                </v-col>
                <v-col cols="2">
                  <a href="mailto:kenkenstoic@gmail.com?subject=Audreyへの問い合わせ&amp;body=ご記入ください">
                    <v-img src="https://res.cloudinary.com/audreyjapan/image/upload/v1646212387/email_a1mtet.png" width="35px"></v-img>
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-list-item>
</v-list>
</v-menu>
</div>


  <router-view></router-view>
<v-footer app> </v-footer>
</v-app>
</div>
</template>

<script>

</script>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-DNYLHGEF04"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-DNYLHGEF04');
</script>
<style>
#app{
  min-width: 300px;
}
.routerFrame{
      margin: 64px auto;
     width: 700px;
}
@media screen and (max-width:700px) { 
.routerFrame{
      margin: 64px auto;
      width: 100%;
}
}
.v-btn{
  min-width: 35px!important;
  box-shadow: none!important;
}
.menuApp{
  position: fixed!important;
  top: 14px;
  right: 14px;
  z-index: 5000!important;
}
.textMargin{
  margin: 21px;
}
.customeButton{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif!important;
  font-size: 14px;
  font-weight: bold!important;
}
.btns{
margin: 21px;
text-align: center;
}
h1{
  color: white;
}
.rightBar h2{
  color: white;
  margin-top: 21px;
  text-align: center;
  font-size: 21px;
}
.rightBar b{
  color: white;
  margin: 7px;
  margin-bottom: 21px;
}
.deviceImageClass{
text-align: center;
  margin-top: 49px;
  width: 100%;
}

.rightBar{
   background-color: #2c2c2e;
  width: 90%;
  height: auto;
  display: block!important;
    padding: 14px;
  margin: auto;
  border-radius: 14px;
  min-width: 280px;
  transition: 1s;
}


.rightBar img{
  width: 45%;
  margin: auto;
  text-align: center;
  
}
.mainBackGround{
  background-color: #2c2c2e!important;
  position: fixed!important;
  z-index: 5000;
}
.mains{
  width: 700px;
  display: inline-block!important;
  margin: auto;
    vertical-align: top;
}
.v-menu__content{
  width: 40%!important;
  max-width: 100%!important;
}
@media screen and (max-width:1300px) { 
.v-menu__content{
  width: 100%!important;
  max-width: 100%!important;
}
}
.theme--light.v-list{
  background: #3d3d3e!important;
}
.v-application__wrap {
  background-color: #2c2c2e;
}
.v-list-item a{
  color: white!important;
  text-decoration: none!important;
}
</style>