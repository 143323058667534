<template>
<v-app>
    <div class="main">
        <h1>Audreyにおけるデータポリシー</h1>
        <br><br>
        <b>データの取り扱い</b><br>
        Audrey内部でユーザーが投稿したコンテンツやメディアがユーザーの個人情報を特定したり、それらの情報を流用、または私的に使用することはありません。これらはAudreyのエンジニアの就業規則としてエンジニアが認識することを義務としています。
        Audreyはお客様の体験をよりよくするために、お客様の投稿したコンテンツや閲覧しているコンテンツを元にサービスを提供することはAudreyのサービスの一部には存在します。ですが基本的にはAudreyがお客様の個人情報並びにトラッキング情報を元にサービスを提供することは<strong></strong> {{ date }}時点では行なっていません。<br>
        <br><b>データと指標</b>
        <br>
        より良いサービスを提供するために分析や解析のためにお客様の個人情報を除く個人を特定しない形で指標としてAudreyで使用することがあります。 <br><br>
        <b>責任</b>
        <br>
        データに関して当社は厳重に取り扱いを行います。万が一データが不正にアクセスされたりする場合は対処を優先します。
        Audreyは故意にデータを喪失させたり、破損を行うことはありません。
        情報開示に関しては<router-link to="/privacyAndPolicy">プライバシーポリシー</router-link>を参照してください。 <br>
        <br>
            <div>
    </div>  


    </div>
</v-app>
</template>
<script>
    export default {
        name: "DateComponent",
        data: () => ({
          date: '',
          time: '',
          year: '',
          timestamp: '',
          fulldatetime: ''
        }),
        methods: {
          printDate: function () {
            return new Date().toLocaleDateString();
          },
          printTime: function () {
            return new Date().toLocaleTimeString();
          },
          printYear: function () {
            return new Date().getFullYear();
          },          
          printTimestamp: function () {
            return Date.now();
          },
          printFullDate: function(){
            return new Date();
          }
        },
        mounted: function () {
          this.date = this.printDate();
          this.time = this.printTime();
          this.timestamp = this.printTimestamp();
          this.year = this.printYear();
          this.fulldatetime = this.printFullDate();
        },
    };
</script>

<style>
.main{
    width: 700px;
    margin:  7% auto;
    background-image: linear-gradient(155deg, #383838 9%, #131415 100%);
    height: 100%;
    border-radius: 21px;
    padding: 14px;
    color: white;
    font-size: 14px;
}

@media screen and (max-width:700px) { 
.main{
    width: 95vw;
    margin:  1% auto;
    background-image: linear-gradient(155deg, #383838 9%, #131415 100%);
    height: 100%;
    border-radius: 21px;
        padding: 14px;
        font-size: 12px;
}
.main b{
    font-size: 18px;
}

}</style>